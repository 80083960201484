import { Injectable } from '@angular/core';
import { Async } from '@peca/commons';
import { BehaviorSubject } from 'rxjs';

export interface Insight {
  type: 'text' | 'currency';
  label: string;
  value: string;
}

@Injectable({ providedIn: 'root' })
export class InsightsService {
  private _insights: Async<Insight[]>;
  expanded$: BehaviorSubject<boolean>;

  constructor() {
    this.expanded$ = new BehaviorSubject(false);
    this._insights = new Async<Insight[]>();
  }

  get insights() {
    return this._insights.state$;
  }

  get expanded() {
    return this.expanded$.value;
  }

  loading() {
    this._insights.loading();
  }

  failed() {
    this._insights.failed();
  }

  push(insights: Insight[]) {
    this._insights.loaded(insights);
  }

  clear() {
    this._insights.loaded([]);
  }
}
