import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { InsightsService } from '@peca/design-system';

@Component({
  selector: 'pds-main-navigation-insights',
  templateUrl: './main-navigation-insights.component.html',
  styleUrls: ['./main-navigation-insights.component.scss'],
  imports: [CommonModule],
})
export class MainNavigationInsightsComponent {
  expanded: boolean;
  insightsServices: InsightsService;

  constructor() {
    this.expanded = false;
    this.insightsServices = inject(InsightsService);
  }

  get insights$() {
    return this.insightsServices.insights;
  }

  onClickToogleSidebar() {
    this.insightsServices.expanded$.next(!this.expanded);
    this.expanded = !this.expanded;
  }
}
