import { Routes } from '@angular/router';
import { BuyersComponent } from './components/buyers/buyers.component';
import { BuyerNaturalPersonFormComponent } from './components/buyer-natural-person/buyer-natural-person-form/buyer-natural-person-form.component';
import { BuyerNaturalPersonListComponent } from './components/buyer-natural-person/buyer-natural-person-list/buyer-natural-person-list.component';
import { BuyerLegalPersonListComponent } from './components/buyer-legal-person/buyer-legal-person-list/buyer-legal-person-list.component';
import { BuyerContainerFormComponent } from './components/buyer-container-form/buyer-container-form.component';
import { BuyerContainerFormTabsComponent } from './components/buyer-container-form-tabs/buyer-container-form-tabs.component';
import { keycloakGuard } from '@peca/keycloak';
import { BuyerExternalListComponent } from './components/buyer-external/buyer-external-list/buyer-external-list.component';
import { BuyerExternalFormComponent } from './components/buyer-external/buyer-external-form/buyer-external-form.component';

export const routes: Routes = [
  {
    path: 'pessoa-fisica/novo',
    component: BuyerNaturalPersonFormComponent,
    canActivate: [keycloakGuard(['compradores-pf-listagem'])],
  },
  {
    path: 'pessoa-fisica/:id',
    component: BuyerNaturalPersonFormComponent,
    canActivate: [keycloakGuard(['compradores-pf-listagem'])],
  },
  {
    path: 'pessoa-fisica',
    component: BuyerNaturalPersonListComponent,
    canActivate: [keycloakGuard(['compradores-pf-listagem'])],
  },
  {
    path: 'pessoa-juridica/novo',
    component: BuyerContainerFormComponent,
  },
  {
    path: 'pessoa-juridica/:id',
    component: BuyerContainerFormTabsComponent,
    children: [
      {
        path: 'detalhes',
        component: BuyerContainerFormTabsComponent,
      },
      {
        path: 'faturas',
        component: BuyerContainerFormTabsComponent,
        canActivate: [keycloakGuard(['compradores-faturas-listagem'])],
      },
    ],
  },
  {
    path: 'pessoa-juridica',
    component: BuyerLegalPersonListComponent,
  },
  {
    path: 'pessoa-externa',
    component: BuyerExternalListComponent,
    canActivate: [keycloakGuard(['compradores-externos-listagem'])],
  },
  {
    path: 'pessoa-externa/:id',
    component: BuyerExternalFormComponent,
    canActivate: [keycloakGuard(['compradores-externos-listagem'])],
  },
  {
    path: '',
    component: BuyersComponent,
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '',
  },
];

export const BuyersModuleLoad = () => import('./buyers.module').then(({ BuyersModule }) => BuyersModule);
