import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BreadcrumbItem, InsightsService, Pagination, PaginationInitialState } from '@peca/design-system';
import { BehaviorSubject, Subject } from 'rxjs';
import { Async } from '@peca/backoffice/utils/async.util';
import { ActivatedRoute, Router } from '@angular/router';
import { Pageable } from '@peca/backoffice/models/pageable.model';
import { ConfirmableActionService } from '@peca/backoffice/services/confirmable-action.service';
import { PersistQueryService } from '@peca/commons';

import { BuyerExternalQuery } from '../../../models/buyer-external/buyer-external-query.model';
import { BuyerExternalService } from '../../../services/buyer-external.service';
import { BuyerExternalResponseList } from '../../../models/buyer-external/buyer-external-response-list.model copy';

@Component({
  templateUrl: './buyer-external-list.component.html',
  styleUrls: ['./buyer-external-list.component.scss'],
  standalone: false,
})
export class BuyerExternalListComponent implements OnInit, OnDestroy {
  breadcrumb: BreadcrumbItem[];
  form: FormGroup;
  pagination$: BehaviorSubject<Pagination>;
  lastQuery: BuyerExternalQuery;
  buyerService: BuyerExternalService;
  buyers: Async<BuyerExternalResponseList[]>;
  router: Router;
  confirmable: ConfirmableActionService;
  insightsService: InsightsService;
  persistQueryService: PersistQueryService;
  route: ActivatedRoute;
  private readonly searchFormInitialValue = {
    search: '',
  };

  constructor() {
    this.breadcrumb = [{ label: 'Compradores', path: '/compradores' }, { label: 'Pessoa externa' }];
    this.form = new FormGroup({
      search: new FormControl(''),
    });
    this.pagination$ = new BehaviorSubject<Pagination>({ ...PaginationInitialState, page: 1 });
    this.lastQuery = { ...PaginationInitialState, page: 1, sort: 'Audit.CreatedAt,desc' };
    this.persistQueryService = inject(PersistQueryService);
    this.route = inject(ActivatedRoute);
    this.buyerService = inject(BuyerExternalService);
    this.buyers = new Async<BuyerExternalResponseList[]>();
    this.router = inject(Router);
    this.confirmable = inject(ConfirmableActionService);
    this.insightsService = inject(InsightsService);

    this.form.valueChanges.subscribe(() => this.checkIfSearchIsPrestine());
  }

  get controls() {
    return {
      search: this.form.get('search') as FormControl,
      sort: this.form.get('sort') as FormControl,
    };
  }

  get value() {
    return {
      search: this.controls.search.getRawValue(),
      sort: this.controls.sort.getRawValue(),
    };
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (!params['q']) return;
      const { search, sort } = JSON.parse(atob(params['q']));
      this.form.patchValue({
        search: search || '',
        sort: sort || 'Audit.CreatedAt,desc',
      });
    });

    const persitedQuery = this.persistQueryService.read<BuyerExternalQuery>('peca-buyer-external-list');
    const query = persitedQuery || this.lastQuery;
    this.fetchBuyers(query);
  }

  ngOnDestroy() {
    this.insightsService.clear();
  }

  fetchBuyers(query: BuyerExternalQuery) {
    this.lastQuery = query;
    this.persistQueryService.rewriteQueryString('peca-buyer-external-list', this.lastQuery);
    this.buyers.loading();
    this.buyerService.fetchBuyers(query).subscribe({
      next: (response) => {
        this.buyers.loaded(response.items);
        this.paginate(response);
      },
      error: () => {
        this.buyers.failed();
      },
    });
  }

  paginate(page: Pageable<unknown>) {
    const pagination = {
      page: page.currentPage,
      size: page.pageSize,
      total: page.totalPages,
      items: page.totalItems,
    };
    this.persistQueryService.rewriteQueryString('peca-buyer-external-list', { ...this.lastQuery, ...pagination });
    this.pagination$.next({ ...pagination });
  }

  onClickSearch() {
    if (this.form.invalid) return;

    const query = { ...PaginationInitialState, page: 1, sort: 'Audit.CreatedAt,desc', ...this.form.value };
    this.fetchBuyers(query);
  }

  clearFilters() {
    this.form.reset(this.searchFormInitialValue);

    const query = { ...PaginationInitialState, page: 1, sort: 'Audit.CreatedAt,desc', ...this.form.value };
    this.fetchBuyers(query);
  }

  checkIfSearchIsPrestine() {
    const currentValue = this.form.value;
    const isFormEmpty = JSON.stringify(currentValue) === JSON.stringify(this.searchFormInitialValue);
    if (isFormEmpty) return this.form.markAsPristine();
    return this.form.markAsDirty();
  }

  onEventRetry() {
    this.fetchBuyers(this.lastQuery);
  }

  onClickDetails(buyer: BuyerExternalResponseList) {
    this.router.navigate(['/compradores', 'pessoa-externa', buyer.id]);
  }

  onEventPaginate(pagination: Pagination) {
    const page = pagination.page;
    const query = { ...this.lastQuery, ...pagination, page };
    this.fetchBuyers(query);
  }

  setMaskDocument(documentType: 'CPF' | 'CNPJ' | undefined) {
    if (documentType === 'CPF') return '999.999.999-99';
    if (documentType === 'CNPJ') return '99.999.999/9999-99';
    return '';
  }
}
