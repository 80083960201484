<pds-module-header title="Compradores" ctaLabel="Voltar" ctaPath="..">
  <pds-new-breadcrumb [items]="breadcrumb" />
  <pds-panel>
    <ng-container *ngIf="buyer$ | async as buyer">
      <ng-container *ngIf="!buyer.loading && !buyer.failure">
        <pds-form [formGroup]="form.group">
          <pds-form-section title="Responsável">
            <pds-form-row grid="1-1">
              <pds-form-field label="Provedor">
                <input type="text" formControlName="provider" />
              </pds-form-field>

              <pds-form-field label="Market Place Id">
                <input type="text" formControlName="marketPlaceId" />
              </pds-form-field>
            </pds-form-row>
            <pds-form-row grid="1-1">
              <pds-form-field label="Nome">
                <input type="text" formControlName="name" />
              </pds-form-field>

              <pds-form-field [label]="buyer.data?.documentType || 'Documento'">
                <input type="text" formControlName="document" [pdsMask]="buyer.data?.documentType === 'CPF' ? '999.999.999-99' : '99.999.999/9999-99'" />
              </pds-form-field>
            </pds-form-row>
          </pds-form-section>

          <pds-form-section title="Contato">
            <pds-form-row grid="1-1-1">
              <pds-form-field label="E-mail" icon="alternate_email">
                <input type="text" formControlName="email" pdsMask="email" [alias]="true" />
              </pds-form-field>
              <pds-form-field label="Celular" icon="smartphone">
                <input type="text" formControlName="cellPhone" pdsMask="(99) 9999-9999[9]" />
              </pds-form-field>

              <pds-form-field label="Telefone" icon="call_end">
                <input type="text" formControlName="phone" pdsMask="(99) 9999-9999" />
              </pds-form-field>
            </pds-form-row>
          </pds-form-section>

          <pds-form-section title="Endereço de cobrança">
            <peca-address formControlName="billingAddress" />
          </pds-form-section>

          <hr />

          <pds-form-actions>
            <button *ngIf="!!buyerId && form.group.disabled" pdsButton (click)="onClickEdit()">editar</button>
            <button *ngIf="!!buyerId && form.group.enabled" pdsButton="primary" (click)="onClickCancel()">cancelar</button>
            <button *ngIf="form.group.enabled" pdsButton [disabled]="form.group.invalid" (click)="onClickSave()">salvar</button>
          </pds-form-actions>
        </pds-form>
      </ng-container>

      <ng-container *ngIf="buyer.loading">
        <strong>carregando...</strong>
      </ng-container>

      <ng-container *ngIf="buyer.failure">
        <peca-retry context="detalhes do comprador" (retry)="onRetry()" />
      </ng-container>
    </ng-container>
  </pds-panel>
</pds-module-header>
