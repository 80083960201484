<pds-module-header title="Compradores">
  <pds-new-breadcrumb [items]="[{ label: 'Compradores' }]" />

  <div class="buyers">
    <div class="buyers__card" role="link" [routerLink]="['/compradores', 'pessoa-juridica']" title="Pessoa Jurídica">
      <h2 class="buyers__card__title">Pessoa Jurídica</h2>
      <div class="buyers__card__subtitle">Cadastro de oficinas</div>
    </div>

    <div *allowTo="[['compradores-pf-listagem']]" class="buyers__card" role="link" [routerLink]="['/compradores', 'pessoa-fisica']" title="Pessoa Física">
      <h2 class="buyers__card__title">Pessoa Física</h2>
      <div class="buyers__card__subtitle">Cadastro de compradores B2C</div>
    </div>

    <div *allowTo="[['compradores-externos-listagem']]" class="buyers__card" role="link" [routerLink]="['/compradores', 'pessoa-externa']" title="Pessoa Externa">
      <h2 class="buyers__card__title">Pessoa Externa</h2>
      <div class="buyers__card__subtitle">Atualização de compradores externos</div>
    </div>
  </div>
</pds-module-header>
