import { Pagination, PaginationInitialState, BreadcrumbItem, InsightsService } from '@peca/design-system';
import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { AsyncState } from '@peca/backoffice/models/async-state.model';
import { Pageable } from '@peca/backoffice/models/pageable.model';
import { AnalyticsService, DocumentPreviewComponent, PersistQueryService } from '@peca/commons';
import { OrderService } from '../../services/order.service';
import { Order } from '../../models/order.model';
import { OrderQuery } from '../../models/order-query.model';

@Component({
  selector: 'peca-order-list',
  templateUrl: './order-list.component.html',
  styleUrl: './order-list.component.scss',
  standalone: false,
})
export class OrderListComponent implements OnInit, OnDestroy {
  @ViewChild('documentPreview')
  documentPreview!: DocumentPreviewComponent;
  destroyRef$: Subject<void>;
  orderService: OrderService;
  orders$: BehaviorSubject<AsyncState<Order[]>>;
  lastQuery: OrderQuery;
  pagination$: BehaviorSubject<Pagination>;
  router: Router;
  breadcrumb: BreadcrumbItem[] = [{ label: 'Vendas', path: '/vendas' }];
  analytics: AnalyticsService;
  persistQueryService: PersistQueryService;
  insightsService: InsightsService;
  insightsExpansion: Subscription;

  constructor() {
    this.analytics = inject(AnalyticsService);
    this.orderService = inject(OrderService);
    this.router = inject(Router);
    this.persistQueryService = inject(PersistQueryService);
    this.insightsService = inject(InsightsService);
    this.destroyRef$ = new Subject<void>();
    this.orders$ = new BehaviorSubject<AsyncState<Order[]>>({ loading: true });
    this.lastQuery = {
      sort: 'audit.createdAt,desc',
      ...PaginationInitialState,
      page: 1,
      size: 36,
    };
    this.pagination$ = new BehaviorSubject<Pagination>({ ...PaginationInitialState, page: 1 });
    this.insightsExpansion = new Subscription();
  }

  get query() {
    const persitedQuery = this.persistQueryService.read<OrderQuery>('peca-order-list');
    const query = persitedQuery || this.lastQuery;
    if (query?.page === 0) query.page = 1;
    return query;
  }

  ngOnDestroy() {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
    this.insightsService.clear();
  }

  ngOnInit() {
    this.fetchOrders(this.query);
    this.handleInsightsExpansion();
  }

  handleInsightsExpansion() {
    this.insightsExpansion = this.insightsService.expanded$.pipe(takeUntil(this.destroyRef$)).subscribe((expanded: boolean) => {
      if (!expanded) return;
      this.analytics.view('insights', { module: 'orders' });
      this.fetchInsights();
    });
  }

  fetchInsights() {
    const { page, size, sort, items, total, disabled, ...query } = this.query as any;
    this.insightsService.loading();

    this.lastQuery = query;
    this.orderService.fetchSummaries(query).subscribe({
      next: (summary) => this.pushInsights(summary),
      error: () => this.insightsService.failed(),
    });
  }

  fetchOrders(orderQuery: OrderQuery) {
    this.orders$.next({ loading: true });
    this.persistQueryService.rewriteQueryString('peca-order-list', orderQuery);
    const { buyer, ...query } = orderQuery;

    this.lastQuery = query;
    this.orderService.fetch(query).subscribe({
      next: this.onFetchOrdersSuccess.bind(this),
      error: this.onFetchOrdersFailure.bind(this),
    });
  }

  pushInsights(insights?: any) {
    this.insightsService.push([
      {
        type: 'text',
        label: 'Total de pedidos',
        value: insights?.totalOrders || '-',
      },
      {
        type: 'currency',
        label: 'Valor total',
        value: insights?.amount || '0',
      },
    ]);
  }

  onFetchOrdersSuccess(orders: Pageable<Order>) {
    const { items } = orders;
    this.paginate(orders);
    this.orders$.next({ data: items });
  }

  paginate(page: Pageable<unknown>) {
    const pagination = {
      page: page.currentPage,
      size: page.pageSize,
      total: page.totalPages,
      items: page.totalItems,
    };
    this.pagination$.next({ ...pagination, page: pagination.page });

    const persitedQuery = this.persistQueryService.read<OrderQuery>('peca-order-list');
    this.persistQueryService.rewriteQueryString('peca-order-list', { ...persitedQuery, ...pagination });
  }

  onFetchOrdersFailure(e: unknown) {
    this.orders$.next({ failure: true });
  }

  onClickExpand(order: Order) {
    const orders = this.orders$.value.data || [];
    const data = orders.map((item) => ({
      ...item,
      expanded: item.id === order.id && !order.expanded,
    }));

    this.orders$.next({ data });
  }

  onSearch(orderQuery: OrderQuery) {
    const pagination = { sort: 'audit.createdAt,desc', ...PaginationInitialState, page: 1, size: 36 };
    const query = {
      ...pagination,
      ...orderQuery,
    };

    this.fetchOrders(query);
    if (this.insightsService.expanded) {
      this.fetchInsights();
    }
  }

  onPaginate(pagination: Pagination) {
    const page = pagination.page <= 0 ? 1 : pagination.page;
    const query = { ...this.lastQuery, ...pagination, page };
    this.fetchOrders(query);
  }

  onClickDetails(order: Order) {
    this.router.navigate(['vendas', order.id]);
  }

  onClickRefresh() {
    this.orders$.next({ loading: true });
    this.pushInsights();
    this.fetchOrders(this.lastQuery);
  }

  onClickPreviewInvoice(order: Order) {
    this.orderService.dowloadInvoice(order.invoice.id).subscribe((res) => {
      this.documentPreview.open({ id: order.id, title: order.commerceReference, type: `application/pdf`, url: res });
    });
  }
}
