import { Directive, ElementRef, inject, Input, OnInit, Renderer2 } from '@angular/core';

export type PDSButtonType = 'default' | 'primary' | 'secondary' | 'terciary' | 'icon' | '';

@Directive({
  selector: 'button[pdsButton],a[pdsButton]',
  standalone: false,
})
export class PDSButtonDirective implements OnInit {
  @Input()
  modifiers?: string;

  @Input()
  pdsButton: PDSButtonType = 'default';

  elementRef = inject(ElementRef);

  renderer = inject(Renderer2);

  get hostElement() {
    return this.elementRef.nativeElement as HTMLElement;
  }

  get currentText() {
    return this.hostElement.innerText;
  }

  firstLetterUppercase(el: HTMLElement | Element) {
    let textFormated = el.innerHTML.trim();
    textFormated = textFormated.split(' ')[0].charAt(0).toUpperCase() + textFormated.slice(1, textFormated.length).toLocaleLowerCase();
    return textFormated;
  }

  ngOnInit() {
    this.renderer.addClass(this.hostElement, 'pds-button');
    this.renderer.addClass(this.hostElement, `pds-button-${this.pdsButton || 'default'}`);
    this.modifiers?.split(' ').forEach((modifier) => this.renderer.addClass(this.hostElement, `--is-${modifier}`));
  }

  ngAfterViewInit() {
    const currentText = this.currentText;
    if (!currentText || this.pdsButton === 'icon') return;

    const elements = this.hostElement.querySelectorAll('p, span, div');

    if (!elements || elements.length <= 0) {
      this.renderer.setProperty(this.hostElement, 'innerText', this.firstLetterUppercase(this.hostElement));
    } else {
      elements.forEach((el) => {
        const formattedText = this.firstLetterUppercase(el);
        if (el.innerHTML !== formattedText) {
          this.renderer.setProperty(el, 'innerHTML', formattedText);
        }
      });
    }
  }
}
