import { Injectable } from '@angular/core';
import { BaseHttpClient } from '@peca/commons';
import { environment } from 'apps/backoffice/src/environments/environment';
import { Pageable } from '@peca/backoffice/models/pageable.model';
import { BuyerLegalPersonQuery } from '../models/buyer-legal-person/buyer-legal-person-query.model';
import { Observable } from 'rxjs';
import { BuyerExternalResponseDetails } from '../models/buyer-external/buyer-external-response-details.model';
import { BuyerExternalResponseList } from '../models/buyer-external/buyer-external-response-list.model copy';

@Injectable({ providedIn: 'root' })
export class BuyerExternalService extends BaseHttpClient {
  private resource = `${environment.gateway.url}/backoffice/v2/external-buyers`;

  update(id: string, payload: unknown) {
    const resource = `${this.resource}/${id}`;
    return this.http.put(resource, payload);
  }

  fetchDetails(id: string) {
    const details = this.http.get<BuyerExternalResponseDetails>(`${this.resource}/${id}`);
    return details;
  }

  fetchBuyers(query: BuyerLegalPersonQuery): Observable<Pageable<BuyerExternalResponseList>> {
    const resource = `${this.resource}`;
    const params = this.prepareQuery(query);
    return this.http.get<Pageable<BuyerExternalResponseList>>(resource, { params });
  }
}
