import { BehaviorSubject } from 'rxjs';
import { ToastService } from '@peca/commons';
import { ActivatedRoute } from '@angular/router';
import { Component, inject, OnInit } from '@angular/core';
import { AsyncState } from '@peca/backoffice/models/async-state.model';
import { ConfirmableActionService } from '@peca/backoffice/services/confirmable-action.service';

import { SettingsSeller } from '../../models/seller-settings.model';
import { SellerSettingService } from '../../services/seller-settings.service';
import { SellerSettingForm } from './seller-setting-form';

@Component({
  selector: 'peca-seller-settings',
  templateUrl: './seller-settings.component.html',
  styleUrl: './seller-settings.component.scss',
  standalone: false,
})
export class SellerSettingsComponent implements OnInit {
  settingService = inject(SellerSettingService);
  route = inject(ActivatedRoute);
  toast = inject(ToastService);
  confirmable = inject(ConfirmableActionService);
  settingForm = new SellerSettingForm();
  settings$ = new BehaviorSubject<AsyncState<SettingsSeller>>({ loading: true });
  sellerId$ = new BehaviorSubject<string>('');

  get sellerId() {
    return this.sellerId$.value;
  }

  ngOnInit() {
    this.route.parent?.params.subscribe(({ sellerId }) => this.sellerId$.next(sellerId));
    this.sellerId$.subscribe(this.fetchSellerSettings.bind(this));
  }

  onRetry() {
    this.fetchSellerSettings(this.sellerId);
  }

  fetchSellerSettings(sellerId: string) {
    this.settingService.fetchSellersSettings(sellerId).subscribe({
      next: (settings) => this.onFetchSuccess(settings),
      error: () => this.handleFetchError(),
    });
  }

  onFetchSuccess(settings: SettingsSeller) {
    if (!settings) {
      this.settings$.next({ loading: false, data: settings });
      return;
    }

    this.settingForm.setValue(settings);
    this.settingForm.disableForm();
    this.settings$.next({ loading: false, data: settings });
  }

  handleFetchError() {
    this.settings$.next({ loading: false, failure: true });
  }

  onClickSave() {
    if (this.settingForm.group.invalid) {
      return;
    }

    const values = this.settingForm.values;
    const sellerSetting = {
      apiCredential: {
        clientId: values?.clientId,
      },
      ftpCredential: {
        host: values?.host,
        password: values?.password,
        username: values?.username,
      },
      type: values?.type,
      considerQmv: values?.considerQMV,
      webhooks: values?.webhooks,
    } as SettingsSeller;

    this.settings$.next({ loading: true });
    this.settingService.updateSettingsSellers(this.sellerId, sellerSetting).subscribe({
      next: (settings) => this.onSaveSuccess(settings),
      error: () => this.handleSaveError(),
    });
  }

  private onSaveSuccess(settings: SettingsSeller) {
    this.toast.success('Vendedor atualizado com sucesso');
    this.settingForm.setValue(settings);
    this.settingForm.disableForm();
    this.settings$.next({ loading: false, data: settings });
  }

  private handleSaveError() {
    this.toast.failure('Falha ao atualizar configurações do vendedor');
    this.fetchSellerSettings(this.sellerId);
    this.settingForm.disableForm();
    this.settings$.next({ loading: false });
  }

  onClickRefreshClientSecret() {
    const message = `Você está atualizando as credenciais do vendedor.".`;
    this.confirmable.confirm<string>(message, this.sellerId, true).subscribe((confirmation) => {
      if (!confirmation.confirmed) return;
      this.settingService.refreshClientSecret(confirmation.payload).subscribe({
        next: () => {
          this.toast.success('Client secret atualizado com sucesso.');
          this.ngOnInit();
        },
        error: () => {
          this.toast.failure('Falha ao atualizar o client secret');
        },
      });
    });
  }

  valiFTPCredentialsdRequired(e: Event) {
    const input = e.target as HTMLInputElement;
    const { host, username, password, type } = this.settingForm.values;
    const value = input.value;
    const isRequired = !!(!!value || host || username || password || type);

    this.settingForm.setFTPCredentialsRequired(isRequired);
  }

  onClickCancel() {
    this.fetchSellerSettings(this.sellerId);
    this.settingForm.disableForm();
    this.settingForm.setFTPCredentialsRequired(false);
  }

  onClickRemoveWebhook() {
    this.settingForm.controls.webhooks.reset({});
  }

  onClickEnable() {
    this.settingForm.enableForm();
  }

  onClickInitSettings() {
    this.settings$.next({ loading: true });
    this.settingService.initSettings(this.sellerId).subscribe({
      next: (settings) => {
        this.settingForm.setValue(settings);
        this.settingForm.disableForm();
        this.settings$.next({ loading: false, data: settings });
      },
      error: () => {
        this.toast.failure('Não foi possível iniciar a configuração do vendedor');
        this.settings$.next({ loading: false });
      },
    });
  }
}
