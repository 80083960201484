import { FormControl, FormGroup } from '@angular/forms';
import { BuyerExternalResponseDetails } from '../models/buyer-external/buyer-external-response-details.model';

export class BuyerExternalForm {
  group: FormGroup;

  constructor() {
    this.group = this.getForm();
  }

  get value() {
    const billingAddress = this.group.get('billingAddress') as FormControl;

    return {
      provider: this.group.get('provider')?.value,
      marketPlaceId: this.group.get('marketPlaceId')?.value,
      name: this.group.get('name')?.value,
      email: this.group.get('email')?.value,
      document: this.group.get('document')?.value,
      documentType: this.group.get('documentType')?.value,
      phone: this.group.get('phone')?.value,
      cellPhone: this.group.get('cellPhone')?.value,
      documentNumberNormalized: this.group.get('documentNumberNormalized')?.value,
      billingAddress: {
        country: billingAddress.value.country,
        street: billingAddress.value.street,
        zipCode: billingAddress.value.zipCode,
        state: billingAddress.value.state,
        city: billingAddress.value.city,
        neighborhood: billingAddress.value.neighborhood,
        number: billingAddress.value.number,
        complement: billingAddress.value.complement,
        description: billingAddress.value.description,
      },
    };
  }

  setValue(buyer: BuyerExternalResponseDetails) {
    this.group.patchValue({
      provider: buyer.provider,
      marketPlaceId: buyer.marketPlaceId,
      name: buyer.name,
      email: buyer.email,
      document: buyer.document,
      documentType: buyer.documentType,
      phone: buyer.phone,
      cellPhone: buyer.cellPhone,
      documentNumberNormalized: buyer.documentNumberNormalized,
      billingAddress: {
        street: buyer.billingAddress.street,
        zipCode: buyer.billingAddress.zipCode,
        state: buyer.billingAddress.state,
        city: buyer.billingAddress.city,
        country: buyer.billingAddress.country,
        neighborhood: buyer.billingAddress.neighborhood,
        number: buyer.billingAddress.number,
        complement: buyer.billingAddress.complement,
        description: buyer.billingAddress.description,
      },
    });
  }

  get controls() {
    return {
      provider: this.group.get('provider') as FormControl,
      marketPlaceId: this.group.get('marketPlaceId') as FormControl,
      name: this.group.get('name') as FormControl,
      email: this.group.get('email') as FormControl,
      document: this.group.get('document') as FormControl,
      documentType: this.group.get('documentType') as FormControl,
      phone: this.group.get('phone') as FormControl,
      cellPhone: this.group.get('cellPhone') as FormControl,
      documentNumberNormalized: this.group.get('documentNumberNormalized') as FormControl,
      country: this.group.get('country') as FormControl,
      billingAddress: this.group.get('billingAddress') as FormControl,
      street: this.group.get('street') as FormControl,
      zipCode: this.group.get('zipCode') as FormControl,
      state: this.group.get('state') as FormControl,
      city: this.group.get('city') as FormControl,
      neighborhood: this.group.get('neighborhood') as FormControl,
      number: this.group.get('number') as FormControl,
      complement: this.group.get('complement') as FormControl,
      description: this.group.get('description') as FormControl,
    };
  }

  private getForm(): FormGroup {
    return new FormGroup({
      provider: new FormControl(''),
      marketPlaceId: new FormControl(''),
      name: new FormControl(''),
      email: new FormControl(''),
      document: new FormControl(''),
      documentType: new FormControl(''),
      phone: new FormControl(''),
      cellPhone: new FormControl(''),
      documentNumberNormalized: new FormControl(''),
      billingAddress: new FormControl({
        country: new FormControl(''),
        street: new FormControl(''),
        zipCode: new FormControl(''),
        state: new FormControl(''),
        city: new FormControl(''),
        neighborhood: new FormControl(''),
        number: new FormControl(''),
        complement: new FormControl(''),
        description: new FormControl(''),
      }),
    });
  }

  enable() {
    this.controls.billingAddress.enable();
  }
}
