import { Injectable, signal, WritableSignal } from '@angular/core';

export interface MainNavigationTab {
  label: string;
  icon: string;
  notifaication: number;
  path: string;
  roles: Array<Array<string>>;
}

@Injectable({ providedIn: 'root' })
export class MainNavigationService {
  tabs: WritableSignal<MainNavigationTab[] | null>;

  constructor() {
    this.tabs = signal<MainNavigationTab[] | null>(null);
  }

  updateNotificationTab(tabLabel: string, notification: number): void {
    if (this.tabs()) {
      this.tabs.update((tabs) => {
        if (tabs) {
          const orderTab = tabs.find((tab) => tab.label === tabLabel);
          if (orderTab) {
            orderTab.notifaication = 0;
            orderTab.notifaication = notification;
          }
        }
        return tabs;
      });
    }
  }

  incrementNotificationTab(tabLabel: string): void {
    if (this.tabs()) {
      this.tabs.update((tabs) => {
        if (tabs) {
          const targetTab = tabs.find((tab) => tab.label === tabLabel);
          if (targetTab) {
            targetTab.notifaication += 1;
          }
        }
        return tabs;
      });
    }
  }
}
