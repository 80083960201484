import { Route } from '@angular/router';
import { KeycloakAuthComponent, keycloakGuard } from '@peca/keycloak';
import { PDSMainNavigationComponent } from '@peca/design-system';

import { securityGuard } from './core/guards/security.guard';

import { SellersModuleLoad } from './modules/sellers/sellers.routes';
import { ProductsModuleLoad } from './modules/products/products.routes';
import { CarriersModuleLoad } from './modules/carriers/carriers.routes';
import { BuyersModuleLoad } from './modules/buyers/buyers.routes';
import { OrdersModuleLoad } from './modules/orders/orders.routes';
import { B2bModuleLoad } from './modules/b2b/b2b.routes';
import { VouchersModuleLoad } from './modules/vouchers/vouchers.routes';
import { DeliveriesModuleLoad } from './modules/deliveries/deliveries.routes';
import { SalesChannelsModuleLoad } from './modules/sales-channels/sales-channels.routes';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { PurchaseOrdersModuleLoad } from './modules/purchase-orders/purchase-orders.routes';
import { ForbiddenComponent } from './core/components/forbidden/forbidden.component';
import { ShippginsModuleLoad } from './modules/shippings/shippings.routes';
import { OrdersReturnModuleLoad } from './modules/orders-return/orders-return.routes';
import { TrackingtModuleLoad } from './modules/tracking/tracking.routes';
import { PlatformsModuleLoad } from './modules/platforms/platforms.routes';
import { UsersModuleLoad } from './modules/users/users.routes';

export const routes: Route[] = [
  {
    path: '403',
    component: ForbiddenComponent,
  },
  {
    path: 'auth',
    component: KeycloakAuthComponent,
  },
  {
    path: '',
    component: PDSMainNavigationComponent,
    canActivate: [securityGuard(), keycloakGuard([])],
    children: [
      {
        path: 'vendas',
        loadChildren: OrdersModuleLoad,
        canActivate: [keycloakGuard(['vendas-listagem'])],
      },
      {
        path: 'vendedores',
        loadChildren: SellersModuleLoad,
        canActivate: [keycloakGuard(['vendedores-listagem'])],
      },
      {
        path: 'produtos',
        loadChildren: ProductsModuleLoad,
        canActivate: [keycloakGuard(['produtos-consulta'])],
      },
      {
        path: 'transportadoras',
        loadChildren: CarriersModuleLoad,
        canActivate: [keycloakGuard(['transportadoras-listagem'])],
      },
      {
        path: 'compradores',
        loadChildren: BuyersModuleLoad,
        canActivate: [keycloakGuard(['compradores-listagem'])],
      },
      {
        path: 'b2b',
        loadChildren: B2bModuleLoad,
        canActivate: [keycloakGuard(['b2b-listagem'])],
      },
      {
        path: 'cupons',
        loadChildren: VouchersModuleLoad,
        canActivate: [keycloakGuard(['cupons-listagem'])],
      },
      {
        path: 'fretes',
        loadChildren: DeliveriesModuleLoad,
        canActivate: [keycloakGuard(['fretes-listagem'])],
      },
      {
        path: 'canais-de-venda',
        loadChildren: SalesChannelsModuleLoad,
        canActivate: [keycloakGuard(['canal-de-venda-listagem'])],
      },
      {
        path: 'compras',
        loadChildren: PurchaseOrdersModuleLoad,
        canActivate: [keycloakGuard(['compras-listagem'])],
      },
      {
        path: 'devolucoes',
        loadChildren: OrdersReturnModuleLoad,
        canActivate: [keycloakGuard(['devolucoes-listagem'])],
      },
      {
        path: 'entregas',
        loadChildren: ShippginsModuleLoad,
        canActivate: [keycloakGuard(['entregas-listagem'])],
      },
      {
        path: 'rastreios',
        loadChildren: TrackingtModuleLoad,
        canActivate: [keycloakGuard(['rastreios-listagem'])],
      },
      {
        path: 'gestao-de-plataforma',
        loadChildren: PlatformsModuleLoad,
        canActivate: [keycloakGuard(['plataformas-listagem'])],
      },
      {
        path: 'usuarios',
        loadChildren: UsersModuleLoad,
        canActivate: [keycloakGuard(['usuarios-listagem'])],
      },
      {
        path: '',
        component: DashboardComponent,
      },
      {
        path: '**',
        redirectTo: '',
        pathMatch: 'full',
      },
    ],
  },
];
