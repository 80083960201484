<pds-module-header title="Compradores" *ngIf="buyers.state$ | async; let buyers">
  <pds-new-breadcrumb [items]="breadcrumb" />

  <pds-panel>
    <div class="buyer-list-search">
      <pds-form [formGroup]="form">
        <pds-form-row grid="1">
          <pds-form-field label="Busca">
            <input type="text" placeholder="Pesquise por CNPJ/CPF" formControlName="search" />
          </pds-form-field>
        </pds-form-row>

        <pds-form-actions>
          <button pdsButton="primary" (click)="clearFilters()" type="button" [disabled]="form.pristine">Limpar filtros</button>
          <button pdsButton title="Buscar" (click)="onClickSearch()">Buscar</button>
        </pds-form-actions>
      </pds-form>
    </div>

    <hr />

    @if (buyers.data?.length) {
    <pds-table>
      <table>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Canal de venda</th>
            <th>Documento</th>
            <th>Endereço</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          @for (buyer of buyers.data; track $index) {
          <tr data-testid="buyer-list-item">
            <td>{{ buyer.name }}</td>
            <td>{{ buyer.provider }}</td>
            <td [pdsMask]="setMaskDocument(buyer?.documentType)">{{ buyer.document }}</td>
            <td>{{ buyer.address }}</td>
            <td pdsColActions>
              <button pdsButton="icon" (click)="onClickDetails(buyer)">
                <span class="icon">visibility</span>
              </button>
            </td>
          </tr>
          }
        </tbody>
      </table>
    </pds-table>
    } @else if (buyers.data && !buyers.data.length) {
    <strong>Nenhum comprador encontrado.</strong>
    } @else if (buyers.loading) {
    <strong>carregando...</strong>
    } @else if (buyers.failed) {
    <peca-retry context="compradores" (retry)="onEventRetry()" />
    }
    <pds-pagination [pagination]="pagination$" (paginate)="onEventPaginate($event)" />
  </pds-panel>
</pds-module-header>
