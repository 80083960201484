import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbItem } from '@peca/design-system';
import { AsyncState } from '@peca/backoffice/models/async-state.model';
import { AddressComponent, AnalyticsService, ToastService } from '@peca/commons';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { ConfirmableActionService } from '@peca/backoffice/services/confirmable-action.service';

import { BuyerExternalService } from '../../../services/buyer-external.service';
import { BuyerExternalResponseDetails } from '../../../models/buyer-external/buyer-external-response-details.model';
import { BuyerExternalForm } from '../../../forms/buyer-external.form';

@Component({
  selector: 'peca-buyer-external-form',
  templateUrl: 'buyer-external-form.component.html',
  styleUrls: ['buyer-external-form.component.scss'],
  standalone: false,
})
export class BuyerExternalFormComponent implements OnInit {
  @ViewChild(AddressComponent) addressComponent!: AddressComponent;
  toast: ToastService;
  route: ActivatedRoute;
  router: Router;
  buyerService: BuyerExternalService;
  confirmableActionService: ConfirmableActionService;
  buyer$: BehaviorSubject<AsyncState<BuyerExternalResponseDetails>>;
  form: BuyerExternalForm;
  breadcrumb!: BreadcrumbItem[];
  analytics: AnalyticsService;

  constructor() {
    this.analytics = inject(AnalyticsService);
    this.route = inject(ActivatedRoute);
    this.buyerService = inject(BuyerExternalService);
    this.toast = inject(ToastService);
    this.router = inject(Router);
    this.confirmableActionService = inject(ConfirmableActionService);
    this.buyer$ = new BehaviorSubject<AsyncState<BuyerExternalResponseDetails>>({ loading: true });
    this.form = new BuyerExternalForm();
    this.initializeBreadcrumb();
  }

  get buyerId() {
    return this.route.snapshot.params['id'] as string;
  }

  ngOnInit() {
    this.fetchBuyerDetails(this.buyerId);
  }

  initializeBreadcrumb() {
    this.breadcrumb = [
      { label: 'Compradores', path: '../..' },
      { label: 'Pessoa externa', path: '..' },
    ];
  }

  fetchBuyerDetails(id: string) {
    this.buyer$.next({ loading: true });
    this.buyerService.fetchDetails(id).subscribe({
      next: (details) => {
        this.initializeBreadcrumb();
        this.breadcrumb.push({ label: details.name });
        this.buyer$.next({ data: details });
        this.form.setValue(details);
        this.form.group.disable();
      },
      error: () => this.buyer$.next({ failure: true }),
    });
  }

  normalizeZipCode(zipCode: string) {
    return String(zipCode).replace(/^(\d{5})(\d{3})$/, '$1-$2');
  }

  update() {
    const { billingAddress } = this.form.value;

    const payload = {
      zipCode: this.normalizeZipCode(billingAddress.zipCode),
      country: this.buyer$.value.data?.billingAddress.country,
      state: billingAddress.state,
      city: billingAddress.city,
      neighborhood: billingAddress.neighborhood,
      street: billingAddress.street,
      number: billingAddress.number,
    };

    this.buyerService.update(this.buyerId, payload).subscribe({
      next: () => {
        this.toast.success('Comprador salvo com sucesso.');
        this.router.navigate(['compradores', 'pessoa-externa']);
      },
      error: () => this.toast.failure('Falha ao alterar o comprador.'),
    });
  }

  onRetry() {
    this.fetchBuyerDetails(this.buyerId);
  }

  onClickEdit() {
    this.form.enable();

    if (this.addressComponent) {
      this.addressComponent.controls.complement.disable();
      this.addressComponent.controls.description.disable();
    }
  }

  onClickCancel() {
    this.form.group.disable();
    this.fetchBuyerDetails(this.buyerId);
  }

  onClickSave() {
    if (this.form.group.invalid) return;

    this.update();
  }
}
