import { Injectable } from '@angular/core';
import { BaseHttpClient } from '@peca/commons';
import { combineLatest, map } from 'rxjs';

import { Seller } from '../models/seller.model';
import { Branch } from '../models/branch.model';
import { SellerQuery } from './seller-query.model';
import { Pageable } from '../../../core/models/pageable.model';
import { environment } from '../../../../environments/environment';
import { ProductManually } from '../models/product-manually.model';

@Injectable({ providedIn: 'root' })
export class SellerService extends BaseHttpClient {
  private readonly api;

  constructor() {
    super();
    this.api = `${environment.gateway.url}/backoffice/v1/sellers`;
  }

  fetchSellers(query: SellerQuery) {
    const params = this.prepareQuery(query);
    return this.http.get<Pageable<Seller>>(this.api, { params });
  }

  updateSellerStatus(id: number, active: boolean) {
    const resource = `${this.api}/${id}/active`;
    return this.http.patch(resource, { active });
  }

  updateBranchStatus(id: number, active: boolean) {
    const resource = `${this.api}/branches/${id}/active`;
    return this.http.patch(resource, { active });
  }

  fetchSellerDetails(id: string) {
    const resource = `${this.api}/${id}`;
    return this.http.get<Seller>(resource);
  }

  fetchSellerDetailsFully(id: string) {
    const resource = `${this.api}/${id}`;
    return combineLatest([this.http.get<Seller>(resource), this.fetchSellerBranches(id)]).pipe(
      map(([seller, branches]) => ({ ...seller, branches } as Seller))
    );
  }

  fetchSellerBranches(id: string) {
    const resource = `${this.api}/${id}/branches`;
    return this.http.get<Array<Branch>>(resource).pipe(
      map((branches) =>
        branches.sort((a, b) => {
          if (a.externalReference < b.externalReference) {
            return 1;
          }
          if (a.externalReference > b.externalReference) {
            return -1;
          }
          return 0;
        })
      )
    );
  }

  fetchBranchDetails(sellerId: string, branchId: string) {
    const branch = `${this.api}/branches/${branchId}`;
    const seller = `${this.api}/${sellerId}`;
    return combineLatest([this.http.get<Branch>(branch), this.http.get<Seller>(seller)]).pipe(map(([branch, seller]) => ({ ...branch, seller } as Branch)));
  }

  createSeller(branch: Branch) {
    const seller = {
      name: branch.seller.name,
      fragaReference: branch.seller.fragaReference,
      email: branch.seller.email,
      branchDetails: {
        externalReference: branch.externalReference,
        commercialName: branch.commercialName,
        description: branch.description,
        email: branch.email,
        responsible: branch.responsible,
        cnpj: branch.cnpj,
        companyName: branch.companyName,
        tradingName: branch.tradingName,
        minimumOrderValue: this.parseNumber(branch.minimumOrderValue),
        convenienceFee: this.parseNumber(branch.convenienceFee),
        address: branch.address,
        businessHours: branch.businessHours,
        salesModel: branch.salesModel,
        pickingTime: this.parseNumber(branch.pickingTime),
        businessUnit: branch.businessUnit,
        billingType: branch.billingType,
        billingMethodId: branch.billingMethod.id,
        paymentDeadlineInDays: branch.paymentDeadlineInDays,
        activityAreaId: branch.activityAreaId,
        cnpjBilling: branch.cnpjBilling,
      },
    };
    return this.http.post<Branch>(this.api, seller);
  }

  createBranch(branch: Branch, sellerId: string) {
    const seller = {
      externalReference: branch.externalReference,
      commercialName: branch.commercialName,
      description: branch.description,
      email: branch.email,
      responsible: branch.responsible,
      cnpj: branch.cnpj,
      companyName: branch.companyName,
      tradingName: branch.tradingName,
      minimumOrderValue: this.parseNumber(branch.minimumOrderValue),
      convenienceFee: this.parseNumber(branch.convenienceFee),
      address: branch.address,
      businessHours: branch.businessHours,
      salesModel: branch.salesModel,
      pickingTime: this.parseNumber(branch.pickingTime),
      businessUnit: branch.businessUnit,
      billingType: branch.billingType,
      billingMethodId: branch.billingMethod.id,
      paymentDeadlineInDays: branch.paymentDeadlineInDays,
      cnpjBilling: branch.cnpjBilling,
      activityAreaId: branch.activityAreaId,
    };
    return this.http.post<Branch>(`${this.api}/${sellerId}/branches`, seller);
  }

  updateBranch(id: string, branch: Branch) {
    const resource = `${this.api}/branches/${id}`;
    const seller = {
      externalReference: branch.externalReference,
      commercialName: branch.commercialName,
      description: branch.description,
      email: branch.email,
      responsible: branch.responsible,
      cnpj: branch.cnpj,
      companyName: branch.companyName,
      tradingName: branch.tradingName,
      minimumOrderValue: this.parseNumber(branch.minimumOrderValue),
      convenienceFee: this.parseNumber(branch.convenienceFee),
      address: branch.address,
      businessHours: branch.businessHours,
      salesModel: branch.salesModel,
      pickingTime: this.parseNumber(branch.pickingTime),
      businessUnit: branch.businessUnit,
      billingType: branch.billingType,
      billingMethodId: branch.billingMethod.id,
      paymentDeadlineInDays: branch.paymentDeadlineInDays,
      cnpjBilling: branch.cnpjBilling,
      activityAreaId: branch.activityAreaId,
    };

    return this.http.patch<Branch>(resource, seller);
  }

  updateSeller(seller: Seller, sellerId: string) {
    const resource = `${this.api}/${sellerId}`;
    return this.http.patch(resource, seller);
  }

  addProductManually(branchId: number, product: ProductManually) {
    const resource = `${environment.gateway.url}/backoffice/v1/sellers/branches/${branchId}/products`;
    return this.http.post(resource, product);
  }

  parseNumber(value: string | number) {
    return Number(
      value
        .toString()
        .replace(/,/g, '.')
        .replace(/[^\d.-]/g, '')
    );
  }

  fetchActivityAreas() {
    const resource = `${environment.gateway.url}/backoffice/v1/activity-areas`;
    return this.http.get<{ id: string; name: string }[]>(resource);
  }
}
