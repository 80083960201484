import { BehaviorSubject } from 'rxjs';

export interface AsyncState<T> {
  loading: boolean;
  failed: boolean;
  data: T | null;
}

export class Async<T> {
  private _state$: BehaviorSubject<AsyncState<T>>;

  constructor() {
    this._state$ = new BehaviorSubject<AsyncState<T>>({ loading: false, failed: false, data: null });
  }

  get state$() {
    return this._state$.asObservable();
  }

  get data() {
    return this._state$.value.data;
  }

  loading() {
    this._state$.next({ loading: true, failed: false, data: null });
  }

  failed() {
    this._state$.next({ loading: false, failed: true, data: null });
  }

  loaded(data: T) {
    this._state$.next({ loading: false, failed: false, data });
  }

  clear() {
    this._state$.next({ loading: false, failed: false, data: null });
  }
}
