import { Component, EventEmitter, OnInit, Output, effect, inject, input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { OrderQuery } from '../../models/order-query.model';
import { AutocompleteItemSource } from '@peca/design-system';
import { BehaviorSubject } from 'rxjs';
import { OrderService } from '../../services/order.service';
import { LogTypeStatusPipe } from '@peca/backoffice/pipes/logs/order-log-type-status.pipe';
import { StatusValues } from '../../values/status.value';
import { ActivatedRoute } from '@angular/router';
import { PersistQueryService } from '@peca/commons';

@Component({
  selector: 'peca-order-search',
  templateUrl: './order-search.component.html',
  styleUrl: './order-search.component.scss',
  standalone: false,
})
export class OrderSearchComponent implements OnInit {
  @Output()
  search: EventEmitter<OrderQuery>;

  isLoading = input(true);
  form: FormGroup;
  buyers$: BehaviorSubject<AutocompleteItemSource>;
  service: OrderService;
  statusSource: any[];
  salesChannels: any[];
  route: ActivatedRoute;
  persistQueryService: PersistQueryService;

  private readonly initialValue = {
    status: [],
    date: { startDate: '', endDate: '' },
    search: '',
    buyer: '',
    salesChannelId: [],
    externalBuyerDocument: '',
    externalReference: '',
  };

  constructor() {
    this.service = inject(OrderService);
    this.route = inject(ActivatedRoute);
    this.persistQueryService = inject(PersistQueryService);
    this.buyers$ = new BehaviorSubject<AutocompleteItemSource>({});
    this.search = new EventEmitter<OrderQuery>();
    this.form = new FormGroup({
      status: new FormControl([]),
      date: new FormControl({ startDate: '', endDate: '' }),
      search: new FormControl(''),
      buyer: new FormControl(''),
      salesChannelId: new FormControl(''),
      externalBuyerDocument: new FormControl(''),
      externalReference: new FormControl(''),
    });
    this.statusSource = this.getStatusSource();
    this.salesChannels = [];
    effect(() => {
      this.isLoading() ? this.form.disable() : this.form.enable();
    });
  }

  get value() {
    return {
      search: (this.form.get('search') as FormControl).value,
      status: (this.form.get('status') as FormControl).value,
      startDate: (this.form.get('date') as FormControl).value?.startDate,
      endDate: (this.form.get('date') as FormControl).value?.endDate,
      buyerId: (this.form.get('buyer') as FormControl).value?.id,
      salesChannelId: (this.form.get('salesChannelId') as FormControl).value,
      buyer: (this.form.get('buyer') as FormControl).value,
      externalBuyerDocument: (this.form.get('externalBuyerDocument') as FormControl).value,
      externalReference: (this.form.get('externalReference') as FormControl).value,
    } as OrderQuery;
  }

  ngOnInit() {
    this.service.suggestSalesChannels('').subscribe({ next: (source) => (this.salesChannels = source) });
    this.form.valueChanges.subscribe(() => this.checkIfSearchIsPrestine());

    this.handleQueryParams();
  }

  handleQueryParams() {
    this.route.queryParams.subscribe((params) => {
      if (!params['q']) return;

      const { search, status, startDate, endDate, buyer, salesChannelId, externalBuyerDocument, externalReference } = JSON.parse(atob(params['q']));

      this.form.patchValue({
        buyer: buyer || this.initialValue.buyer,
        salesChannelId: salesChannelId || this.initialValue.salesChannelId,
        search: search || this.initialValue.search,
        status: status || this.initialValue.status,
        date: {
          startDate: startDate || this.initialValue.date.startDate,
          endDate: endDate || this.initialValue.date.endDate,
        },
        externalBuyerDocument: externalBuyerDocument || this.initialValue.externalBuyerDocument,
        externalReference: externalReference || this.initialValue.externalReference,
      });
    });
  }

  onSubmit() {
    if (this.form.invalid) return;
    const formValue = this.value;
    const query = Object.keys(formValue).reduce((ac, field) => {
      const key = field as keyof OrderQuery;
      const currentValue = formValue[key] as any;
      if (!currentValue || currentValue.length <= 0) return ac;

      ac[field] = currentValue;
      return ac;
    }, {} as any);
    this.search.next(query);
  }

  clearFilters() {
    this.form.reset(this.initialValue);
    this.onSubmit();
  }

  checkIfSearchIsPrestine() {
    const currentValue = this.form.value;
    const isFormEmpty = JSON.stringify(currentValue) === JSON.stringify(this.initialValue);
    if (isFormEmpty) return this.form.markAsPristine();
    this.form.markAsDirty();
  }

  getStatusSource() {
    const status = [...StatusValues];
    const pipe = new LogTypeStatusPipe();
    return status.map((i) => ({ id: i, label: pipe.transform(i) as string }));
  }

  onAutocompleteBuyers(search: string) {
    if (search.length < 3) return;

    this.buyers$.next({ loading: true });
    this.service.suggestBuyers(search).subscribe({
      next: (data) => this.buyers$.next({ data }),
      error: () => this.buyers$.next({ failure: true }),
    });
  }
}
